import React, { useEffect, useState } from "react";
import classes from "./DoctorBooking.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";
import BookingService from "../../services/booking";
import DeptCapitalise from "../../components/Decapitalize";
import SlotAndTime from "./steps/SlotAndTime";
import VerifyOtp from "./steps/VerifyOtp";
import PatientSelection from "./steps/PatientSelection";
import Confirmation from "./steps/Confirmation";

const DoctorBooking = () => {
  const { doctorId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dateParam = params.get("date");
  const [doctorDetails, setDoctorDetails] = useState();
  console.log("doctor details", doctorDetails);
  const [doctorsLoading, setDoctorsLoading] = useState(false);
  const [bookingStep, setBookingStep] = useState(1);
  // const [selectedSlot, setSelectedSlot] = useState();
  const registerFormDataParameters = {
    visit_id: "",
    mobileNumber: "",
    otp: "",
    date: "",
    doctor_name: "",
    consultation_time: "",
    token_id: "",
    token: "",
    encrypted_otp: "",
    encrypted_mobile: "",
  };
  const [registerFormData, setRegisterFormData] = useState(
    registerFormDataParameters
  );
  console.log("333 registerFormData", registerFormData);

  const fetchDoctorDetails = async () => {
    try {
      setDoctorsLoading(true);
      if (dateParam) {
        const doctorsDatas = await BookingService.getDoctorsBooking(
          dateParam,
          doctorId
        );
        const doctorsData = doctorsDatas.data;

        // noDataChecker(doctorsData);
        if (doctorsData.length > 0) {
          setDoctorDetails(doctorsData[0]);
        }
        setDoctorsLoading(false);
      } else {
        notification.error({
          message: "Appointment date not available. Please try again.",
        });
        navigate(`/consultation`);
      }
    } catch (err) {
      setDoctorsLoading(false);
      console.log("err", err);
    }
  };

  useEffect(() => {
    fetchDoctorDetails();
  }, []);

  const formatName = (name) => {
    const first = name
      .toLowerCase()
      .split(/[ .]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    const second = first.substring(0, 2) + "." + first.substring(2);

    return second;
  };

  const nextStepHandler = () => {
    setBookingStep((prev) => prev + 1);
    window.scrollTo(0, 0);
  };

  return (
    <div className={classes.container}>
      <div className={classes.bookingSection}>
        <div className={classes.leftSection}>
          <img
            src={
              doctorDetails?.image && doctorDetails?.image != "test"
                ? `data:image/png;base64, ${doctorDetails?.image}`
                : "/img/designs/doc-pic.svg"
            }
            alt="profile"
            className={classes.docImage}
          />
          <div className={classes.docDetails}>
            {doctorDetails?.doctor_name && (
              <h4 className={classes.docName}>
                {formatName(doctorDetails?.doctor_name)}
              </h4>
            )}
            {doctorDetails?.department_name && (
              <h5 className={classes.docDept}>
                {DeptCapitalise(doctorDetails?.department_name)}
              </h5>
            )}
            <div className={classes.qualificationDiv}>
              {doctorDetails?.qualification &&
                doctorDetails?.qualification
                  ?.split(",")
                  ?.map((item) => (
                    <h5 className={classes.docQualification}>{item}</h5>
                  ))}
            </div>
          </div>
        </div>
        <div className={classes.rightSection}>
          <h5 className={classes.appointmentTag}>Appointment Booking</h5>
          {/* {doctorDetails && (
            <> */}
          {bookingStep == 1 && (
            <SlotAndTime
              bookingStep={bookingStep}
              doctorDetails={doctorDetails}
              dateParam={dateParam}
              doctorsLoading={doctorsLoading}
              registerFormData={registerFormData}
              setRegisterFormData={setRegisterFormData}
              nextStepHandler={nextStepHandler}
            />
          )}
          {bookingStep == 2 && (
            <VerifyOtp
              bookingStep={bookingStep}
              doctorDetails={doctorDetails}
              dateParam={dateParam}
              registerFormData={registerFormData}
              setRegisterFormData={setRegisterFormData}
              nextStepHandler={nextStepHandler}
            />
          )}
          {bookingStep == 3 && (
            <PatientSelection
              bookingStep={bookingStep}
              doctorDetails={doctorDetails}
              dateParam={dateParam}
              registerFormData={registerFormData}
              setRegisterFormData={setRegisterFormData}
              nextStepHandler={nextStepHandler}
            />
          )}
          {bookingStep == 4 && (
            <Confirmation
              bookingStep={bookingStep}
              doctorDetails={doctorDetails}
              dateParam={dateParam}
              registerFormData={registerFormData}
              setRegisterFormData={setRegisterFormData}
              nextStepHandler={nextStepHandler}
            />
          )}
          {/* </>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default DoctorBooking;
