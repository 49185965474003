import React, { useState } from "react";
import { ClipLoader, PuffLoader } from "react-spinners";

const LoadingSpinner = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div
      style={{
        textAlign: "center",
        //  marginTop: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '60vh'
      }}
    >
      <PuffLoader color="#7F4A9B" loading={loading} size={50} />
    </div>
  );
};

export default LoadingSpinner;
