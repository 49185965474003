import React, { Suspense, lazy, useState } from "react";
import { Routes, Route } from "react-router-dom";
import LoadingSpinner from "./components/loader/LoadingSpinner";
import NotFoundPage from "./components/not-found-page/NotFoundPage";
import DoctorBooking from "./pages/doctor-booking/DoctorBooking";
import PaymentSuccess from "./pages/payment-pages/PaymentSuccess";
import PaymentFailure from "./pages/payment-pages/PaymentFailure";
import Confirmation from "./pages/doctor-booking/steps/Confirmation";

// Lazy loading the components
const Layout = lazy(() => import("./components/layout/Layout"));
const Home = lazy(() => import("./pages/home-page/Home"));
const AboutUs = lazy(() => import("./pages/about-page/AboutUs"));
const ContactUs = lazy(() => import("./pages/contact-us-page/ContactUs"));
const DoctorsPage = lazy(() => import("./pages/doctors-page/DoctorsPage"));
const BranchHome = lazy(() => import("./pages/branch-page/BranchHome"));
const ServicesListing = lazy(() => import("./pages/services-listing/ServicesListing"));
const ServiceDetail = lazy(() => import("./pages/service-detail/ServiceDetail"));
const NewsAndEvents = lazy(() => import("./pages/news-and-events/NewsAndEvents"));
const NewsDetail = lazy(() => import("./pages/news-and-events-detail/NewsDetail"));
const PagesDetail = lazy(() => import("./pages/pages-detail/PagesDetail"));
const GalleryListing = lazy(() => import("./pages/gallery/gallery-listing/GalleryListing"));
const Testimonials = lazy(() => import("./pages/testimonials/Testimonials"));
const Careers = lazy(() => import("./pages/careers/Careers"));
const BookAppointment = lazy(() => import("./pages/book-appointment/BookAppointment"));
const GalleryDetail = lazy(() => import("./pages/gallery/gallery-detail/GalleryDetail"));
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy/PrivacyPolicy"));

const AppRoutes = () => {
  const [popUpOnce, setpopUpOnce] = useState(0);
  const [popUpOnceBranch, setpopUpOnceBranch] = useState(0);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route
          path="/"
          element={<Home popUpOnce={popUpOnce} setpopUpOnce={setpopUpOnce} />}
        />
        <Route
          path="/branch/:slug"
          element={
            <BranchHome
              popUpOnceBranch={popUpOnceBranch}
              setpopUpOnceBranch={setpopUpOnceBranch}
            />
          }
        />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<ServicesListing />} />
        <Route path="/services/:slug" element={<ServiceDetail />} />
        <Route path="/blogs" element={<NewsAndEvents />} />
        <Route path="/blogs/:slug" element={<NewsDetail />} />
        <Route path="/pages/:slug" element={<PagesDetail />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/gallery" element={<GalleryListing />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/doctors" element={<DoctorsPage />} />
        <Route path="/consultation" element={<BookAppointment />} />
        <Route path="/gallery/:slug" element={<GalleryDetail />} />
        <Route path="/policy/:slug" element={<PrivacyPolicy />} />
        <Route path="/payment-status-success" element={<PaymentSuccess />} />
        <Route path="/payment-status-failure" element={<PaymentFailure />} />
        <Route path="/book-doctor/:doctorId" element={<DoctorBooking />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
