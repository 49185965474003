import React, { useEffect, useState } from "react";
import classes from "./SlotAndTime.module.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, notification, Select } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import BookingService from "../../../services/booking";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const SlotAndTime = ({
  bookingStep,
  doctorDetails,
  dateParam,
  doctorsLoading,
  registerFormData,
  setRegisterFormData,
  nextStepHandler,
}) => {
  const [form] = Form.useForm();
  const [otpLoading, setOtpLoading] = useState(false);
  const [slotTokenLoading, setSlotTokenLoading] = useState(false);
  const [slotTokens, setSlotTokens] = useState([]);
  console.log("slotTokens", slotTokens);
  const navigate = useNavigate();
  //   const [selectedToken, setSelectedToken] = useState();

  const selectSlot = (id) => {
    if (id == registerFormData?.visit_id) {
      setRegisterFormData({ ...registerFormData, visit_id: null });
      //todo - clear the timings state value
    } else {
      setRegisterFormData({ ...registerFormData, visit_id: id });
    }
  };

  const opt = [];

  const slotTokenFetching = async () => {
    setSlotTokenLoading(true);
    const tokenData = await BookingService.getToken(registerFormData.visit_id);
    if (tokenData?.data) {
      //   setToken(tokenData);
      if (tokenData?.status == "error") {
        notification.error({
          message: tokenData?.msg,
        });
        return;
      }

      setSlotTokenLoading(false);
      tokenData?.data?.forEach((item) => {
        let value = {};
        value.value = item.id;
        value.label = item.time_from + "-" + item.time_to;
        value.token = item.token;
        opt.push(value);
      });

      setSlotTokens(opt);
    }
    setSlotTokenLoading(false);
  };

  useEffect(() => {
    if (registerFormData?.visit_id) {
      slotTokenFetching();
    }
  }, [registerFormData.visit_id]);

  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        setOtpLoading(true);
        console.log("333 values", values);
        const selectedToken = slotTokens?.find(
          (item) => item.value == values.slotTime
        );
        console.log("333 tokens list", slotTokens);
        console.log("333 selectedToken", selectedToken);
        // setRegisterFormData({
        //   ...registerFormData,
        //   consultation_time: selectedToken?.label,
        //   token_id: selectedToken?.value,
        //   doctor_name: doctorDetails?.doctor_name,
        //   date: dateParam,
        //   token: selectedToken?.token,
        // });

        const finalkey = await BookingService.contactEncryption(
          values.phoneNumber
        );

        const otpEncoded = await BookingService.generateOtp(
          finalkey,
          registerFormData.visit_id
        );

        if (otpEncoded.status == "success") {
          //   setRegisterFormData({
          //     ...registerFormData,
          //     // encrypted_otp: data.encryptedOtp,
          //     encrypted_mobile: finalkey,
          //     // otp: data.otp,
          //   });
          setRegisterFormData({
            ...registerFormData,
            consultation_time: selectedToken?.label,
            token_id: selectedToken?.value,
            doctor_name: doctorDetails?.doctor_name,
            date: dateParam,
            token: selectedToken?.token,
            encrypted_mobile: finalkey,
            mobileNumber: values.phoneNumber,
          });
          nextStepHandler();
        }

        setOtpLoading(false);
      })
      .catch((err) => {
        setOtpLoading(false);
        console.log("error", err);
      });
  };

  return (
    <div className={classes.sectionContianer}>
      <h4 className={classes.sectionHeading}>
        Book Your <span>Appointment</span>
      </h4>
      <div className={classes.divideLine}></div>
      {doctorsLoading && <LoadingOutlined className={classes.loadingIcon} />}
      {((!doctorsLoading && !doctorDetails) ||
        doctorDetails?.is_outofstation) && (
        <h4 className={classes.notAvailable}>
          Booking not available for this doctor on {dateParam}
        </h4>
      )}
      {!doctorsLoading && doctorDetails && !doctorDetails?.is_outofstation && (
        <>
          <div className={classes.slotsContainer}>
            {doctorDetails?.visits?.map((visit) => (
              <div className={classes.slot}>
                <img
                  className={classes.checkBox}
                  src={
                    visit.visit_id == registerFormData?.visit_id
                      ? "/img/checkbox-selected.svg"
                      : "/img/checkbox.svg"
                  }
                  alt="checkbox"
                  onClick={() => selectSlot(visit.visit_id)}
                />
                <div className={classes.slots}>
                  <div className={classes.timeList}>
                    <img src="/img/clock-icon.svg" alt="clock" />
                    <p className="primary-300">{`${visit.time_from} - ${visit.time_to}`}</p>
                  </div>
                  <h4>{visit.slots_available} Slots</h4>
                </div>
              </div>
            ))}
          </div>
          {doctorDetails?.visits?.some((item) => item?.slots_available > 0) ? (
            <Form
              layout="vertical"
              form={form}
              //   onFinish={onFinish}
            >
              <Form.Item
                label="Available Time Slots"
                name="slotTime"
                rules={[
                  { required: true, message: "Please enter your Username!" },
                ]}
              >
                <Select
                  placeholder="Select a time slot..."
                  showSearch
                  allowClear
                  // onChange={(value) => setSelectedBranch(value)}
                  className="form-select"
                  optionFilterProp="children" // Ensure filtering works on option labels
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  // omChange={(value)=> setSelectedToken(value)}
                >
                  {slotTokens?.map((item) => (
                    <Option className="custom-option" key={item?.value} value={item?.value}>
                      {item?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className={`${classes.inputHalf} phone-input`}
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Phone number!",
                  },
                ]}
              >
                <PhoneInput
                  country={"in"}
                  enableSearch={true}
                  specialLabel=""
                  disableDropdown={false}
                  countryCodeEditable={true}
                  placeholder={"Phone Number"}
                />
                {/* <Input placeholder="Phone Number" /> */}
              </Form.Item>

              <h4 className={classes.message}>
                📩 An OTP will be sent to your registered mobile number for
                verification.
              </h4>
              <Form.Item>
                <button
                  className={`${classes.submitButton} primary-hover`}
                  onClick={() => onFinish()}
                  // htmlType="submit"
                  // block
                >
                  {otpLoading && <LoadingOutlined />} Send OTP
                </button>
                {/* <Button type="primary" htmlType="submit" block>
            Submit
          </Button> */}
              </Form.Item>
            </Form>
          ) : (
            <>
              <h4 className={classes.noDocMessage}>
                Unfortunately the booking for this doctor is completed. Please
                provide us your contact details, we will get back to you!
              </h4>
              <button
                onClick={() => navigate(`/contact-us`)}
                className={`${classes.contactUs} primary-hover`}
              >
                Contact Us
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SlotAndTime;
