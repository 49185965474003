import React, { useEffect, useRef, useState } from "react";
import classes from "./Confirmation.module.css";
import { LoadingOutlined } from "@ant-design/icons";
import BookingService from "../../../services/booking";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

const APP_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const payNowConfirm = async (data) => {
  // const accessToken = window.localStorage.getItem('accesstoken')
  let url = `${APP_BASE_URL}/booking/pay-now-confirm`;
  console.log('cred data', data)

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response)
    .then((data) => data.data)
};

const Confirmation = ({
  bookingStep,
  doctorDetails,
  dateParam,
  registerFormData,
  setRegisterFormData,
  nextStepHandler,
}) => {
  const [payLaterLoading, setPayLaterLoading] = useState(false);
  const navigate = useNavigate();
  console.log('reg form data payment', registerFormData)
  const [data, setData] = useState();
  const buttonRef = useRef(null);

  const formatName = (name) => {
    const first = name
      .toLowerCase()
      .split(/[ .]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    const second = first.substring(0, 2) + "." + first.substring(2);

    return second;
  };

  const payLater = async () => {
    setPayLaterLoading(true);
    let cred = {};
    cred = {
      transaction_id: registerFormData.transaction_id,
      is_free_visit: registerFormData.is_free_visit,
    };
    console.log("pay later data", cred);

    const response = await BookingService.payLater(cred);
    const msg = response.msg;
    if (response.status == "success") {
      navigate("/payment-status-success");
      notification.success({
        message: response.msg,
      });
    } else {
      notification.error({
        message: response.msg,
      });
      navigate("/payment-status-failure");
    }

    console.log("payLater response", response);
    setPayLaterLoading(false);
  };

  const payNow = async () => {
    let cred = {};
    cred = { transaction_id: registerFormData.transaction_id || "423423525" };
    console.log("pay now data", cred);

    const response = await payNowConfirm(cred);

    if (response) {
      console.log("pay now data success");
      console.log("udf1 in response", response);
      const hashValue = await BookingService.getHashValue(response);
      if (hashValue) {
        response.hash = hashValue;
        // redirectToPayU(response);
        // PaymentForm(response)
        setData(response);
      }
    }

    console.log("payNOw response1", response);
  };

  useEffect(() => {
    if (data && buttonRef.current) {
      console.log("useref worked");
      buttonRef.current.click();
    }
  }, [data]);

  return (
    <div className={classes.sectionContianer}>
      <h4 className={classes.sectionHeading}>
        Secure Your <span>Appointment</span>
      </h4>
      <div className={classes.patientSection}>
        <div className={classes.dataRow}>
          <div className={classes.rowLeftSide}>
            <img
              src="/img/icons/book-scope.svg"
              alt="book"
              className={classes.rowIcon}
            />
            <h5 className={classes.rowParameter}>Doctor's Name:</h5>
          </div>
          <h4 className={classes.rowValue}>
            {formatName(registerFormData.doctor_name)}
          </h4>
        </div>
        <div className={classes.divideLine}></div>

        <div className={classes.dataRow}>
          <div className={classes.rowLeftSide}>
            <img
              src="/img/icons/book-date.svg"
              alt="book-date"
              className={classes.rowIcon}
            />
            <h5 className={classes.rowParameter}>Date:</h5>
          </div>
          <h4 className={classes.rowValue}>{registerFormData.date}</h4>
        </div>
        <div className={classes.divideLine}></div>

        <div className={classes.dataRow}>
          <div className={classes.rowLeftSide}>
            <img
              src="/img/icons/book-clock.svg"
              alt="clock"
              className={classes.rowIcon}
            />
            <h5 className={classes.rowParameter}>Consultation Time:</h5>
          </div>
          <h4 className={classes.rowValue}>
            {registerFormData.consultation_time}
          </h4>
        </div>
        <div className={classes.divideLine}></div>

        <div className={classes.dataRow}>
          <div className={classes.rowLeftSide}>
            <img
              src="/img/icons/book-slip.svg"
              alt="book-slip"
              className={classes.rowIcon}
            />
            <h5 className={classes.rowParameter}>Token Number:</h5>
          </div>
          <h4 className={classes.rowValue}>{registerFormData.tokenNo}</h4>
        </div>
        <div className={classes.divideLine}></div>

        <div className={classes.dataRow}>
          <div className={classes.rowLeftSide}>
            <img
              src="/img/icons/book-ruppee.svg"
              alt="book-rupee"
              className={classes.rowIcon}
            />
            <h5 className={classes.rowParameter}>Consultation Fee:</h5>
          </div>
          <h4 className={classes.rowValue}>
            {registerFormData.is_pay_now_enable
              ? "₹ " + registerFormData.net_amount
              : "₹ 0.00"}
          </h4>
        </div>
        {/* <div className={classes.divideLine}></div> */}
      </div>
      <div className={classes.buttonDiv}>
      {!registerFormData.is_pay_now_enable ? (
             <button
             className={`${classes.submitButton} disabled-button primary-hover`}
             // onClick={() => bookAndProcced()}
             // htmlType="submit"
             // block
             disabled={true}
           >
             Pay Now (Not Available)
             {/* {bookingLoading && <LoadingOutlined />} Pay Now */}
           </button>
          ) : (
            ""
          )}
          {registerFormData.is_pay_now_enable ? (
        <button
          className={`${classes.submitButton} primary-hover`}
          // onClick={() => bookAndProcced()}
          onClick={payNow}
          // htmlType="submit"
          // block
        >
          Pay Now
          {/* {bookingLoading && <LoadingOutlined />} Pay Now */}
        </button>
          ): ""}
        <button
          className={`${classes.submitButton2} primary-hover`}
          onClick={() => payLater()}
          // htmlType="submit"
          // block
        >
          {payLaterLoading && <LoadingOutlined />} Pay Later
        </button>
      </div>
      {data && (
            // <form id="myform" action="https://test.payu.in/_payment-H " method="post">
            <form
              id="myform"
              action={process.env.REACT_APP_PAYU_URL}
              method="post"
            >
              <input type="hidden" name="key" value={process.env.REACT_APP_PAYU_KEY} />
              <input type="hidden" name="txnid" value={data.transaction_id} />
              <input
                type="hidden"
                name="productinfo"
                value={data.product_name}
              />
              <input type="hidden" name="amount" value={data.amount} />
              <input type="hidden" name="email" value={data.email} />
              <input type="hidden" name="firstname" value={data.firstname} />
              <input type="hidden" name="udf1" value="WEB" />
              <input type="hidden" name="bankcode" value="TESTPGNB" />
              {/* <input type="hidden" name="pg" value="bhimupi" /> */}
              <input
                type="hidden"
                name="surl"
                // value={data.surl}
                // value="http://localhost:3000/payment-status-success"
                // value="https://sabinetest.riolabz.com/payment-status-success"
                // value={process.env.REACT_APP_PAYU_SURL}
                value={data.surl}
              />
              <input
                type="hidden"
                name="furl"
                // value={data.furl}
                // value="http://localhost:3000/payment-status-failure"
                // value="https://sabinetest.riolabz.com/payment-status-failure"
                // value={process.env.REACT_APP_PAYU_FURL}
                value={data.furl}
              />
              <input type="hidden" name="phone" value={data.phone_number} />
              {/* <input type="hidden" name="pg" value="NB" />
      <input type="hidden" name="bankcode" value="SBIB" /> */}
              <input type="hidden" name="hash" value={data.hash} />
              <input
                style={{ display: "none" }}
                id="submitbutton"
                ref={buttonRef}
                type="submit"
                value="submit"
              />{" "}
            </form>
          )}
      <h4 className={classes.paymentMessage}>
        <img
          src="/img/icons/book-warn.svg"
          alt="book-warn"
          className={classes.rowIcon}
        />
        Complete Your Transaction In 15mins
      </h4>
    </div>
  );
};

export default Confirmation;
