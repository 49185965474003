import React from "react";

function DeptCapitalise(str) {
  if (str) {
    return str.toLowerCase().replace(/(^|\s)\S/g, (char) => char.toUpperCase());
  } else {
    return str;
  }
}

export default DeptCapitalise;
