import React, { useEffect, useState } from "react";
import classes from "./PatientSelection.module.css";
import BookingService from "../../../services/booking";
import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, notification, Select } from "antd";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

const PatientSelection = ({
  bookingStep,
  doctorDetails,
  dateParam,
  registerFormData,
  setRegisterFormData,
  nextStepHandler,
}) => {
  const [form] = Form.useForm();
  const [addingNewPatient, setAddingNewPatient] = useState(false);
  const [creatingNewPatient, setCreatingNewPatient] = useState(false);
  const [genderList, setGenderList] = useState();
  const [patientData, setPatientData] = useState();
  const [patientDataLoading, setPatientDataLoading] = useState(false);
  console.log("patient data", patientData);
  const [selectedPatient, setSelectedPatient] = useState();
  const [bookingLoading, setBookingLoading] = useState(false);

  const fetchGenderList = async () => {
    const genderData = await BookingService.getGenderList();
    if (genderData.length > 0) {
      const myArray = genderData.map((item) => ({
        value: item.id,
        label: item.gender,
      }));
      setGenderList(myArray);
    }
    // console.log('genderData :', genderData)
  };

  const fetchPatientData = async () => {
    setPatientDataLoading(true);
    const patient = await BookingService.getPatientData(registerFormData);
    if (patient) {
      setPatientData(patient?.data);
      if (patient?.data?.length == 0) {
        setAddingNewPatient(true);
      }
    }
    setPatientDataLoading(false);
  };

  useEffect(() => {
    fetchGenderList();
    fetchPatientData();
  }, []);

  const bookDoctor = async (values) => {
    let sendingValues = { ...values };
    if (!values.email) {
      sendingValues.email = "test@test.com";
    }
    if (!values.op_number) {
      sendingValues.op_number = undefined;
    }
    sendingValues.visit_id = registerFormData.visit_id;
    sendingValues.mobile_number = registerFormData.encrypted_mobile;
    sendingValues.otp = registerFormData.encrypted_otp;
    sendingValues.token = registerFormData.token_id;
    sendingValues.date = registerFormData.date;
    sendingValues.is_web_booking = true;
    sendingValues.telemedicine = false;
    sendingValues.telemedicine_mobile_user = "";
    sendingValues.is_otp_verified = true;

    console.log("sendingValues", sendingValues);
    const confirmResponse = await BookingService.confirmBooking(sendingValues);

    if (confirmResponse.data) {
      setRegisterFormData({
        ...registerFormData,
        transaction_id: confirmResponse.data.transaction_id,
        //   transaction_id: confirmResponse.data.transaction_id || "423423525",
        net_amount: confirmResponse.data.net_amount,
        is_free_visit: confirmResponse.data.is_free_visit || false,
        op_number: values.op_number || "",
        tokenNo: confirmResponse.data.tokenNo || "",
        is_pay_now_enable: confirmResponse.data.is_pay_now_enable,
      });
      nextStepHandler();
      return confirmResponse.data;
    }
    if (confirmResponse.status === "error") {
      notification.error({
        message: confirmResponse.msg,
      });
      return confirmResponse;
    }
  };

  const addPatientAndBook = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setCreatingNewPatient(true);
        console.log("333 values", values);

        const response = await bookDoctor(values);

        setCreatingNewPatient(false);
      })
      .catch((err) => {
        setCreatingNewPatient(false);
        console.log("error", err);
      });
  };

  const bookAndProcced = async () => {
    setBookingLoading(true);
    if (!selectedPatient) {
      notification.error({
        message: "Select a patient to proceed.",
      });
      return;
    } else {
      const response = await bookDoctor(selectedPatient);
    }

    setBookingLoading(false);
  };

  return (
    <div className={classes.sectionContianer}>
      {!addingNewPatient && (
        <h4 className={classes.sectionHeading}>
          Select a <span>Registered Patient</span>
        </h4>
      )}
      {!addingNewPatient && (
        <h5 className={classes.textLine}>
          We’ve found patients linked to this mobile number. Please select a
          patient or add a new one.
        </h5>
      )}
      {addingNewPatient && (
        <h4 className={classes.sectionHeading}>
          Add a <span>New Patient</span>
        </h4>
      )}
      {addingNewPatient && (
        <h5 className={classes.textLine}>
          Add a new patient by filling the form below.
        </h5>
      )}

      <div className={classes.patientSection}>
        {patientDataLoading && (
          <LoadingOutlined className={classes.loadingIcon} />
        )}
        {!patientDataLoading &&
          !addingNewPatient &&
          patientData?.length == 0 && (
            <>
              <h4 className={classes.notAvailable}>No Patients to select </h4>
              <button
                className={`${classes.submitButton2} ${classes.marginAuto} primary-hover`}
                onClick={() => setAddingNewPatient(true)}
                // htmlType="submit"
                // block
              >
                Add New Patient
              </button>
            </>
          )}
        {!patientDataLoading && patientData?.length > 0 && (
          <>
            <div className={classes.patientsContainer}>
              {patientData?.map((patient) => (
                <div className={classes.slot}>
                  <img
                    className={classes.checkBox}
                    src={
                      selectedPatient.op_number === patient.op_number
                        ? "/img/checkbox-selected.svg"
                        : "/img/checkbox.svg"
                    }
                    alt="patient-select"
                    onClick={() => setSelectedPatient(patient)}
                  />
                  <div className={classes.slots}>
                    <h4>
                      OP No:
                      <span className={`${classes.marginRight}`}>
                        {patient.op_number}
                      </span>
                    </h4>
                    <h4>
                      Name:{" "}
                      <span
                        className={`${classes.spanWeight} ${classes.marginRight}`}
                      >
                        {patient.patient_name}
                      </span>
                    </h4>
                  </div>
                </div>
              ))}
            </div>
            <div className={classes.buttonDiv}>
              <button
                className={`${classes.submitButton} primary-hover`}
                onClick={() => bookAndProcced()}
                // htmlType="submit"
                // block
              >
                {bookingLoading && <LoadingOutlined />} Select & Proceed
              </button>
              <button
                className={`${classes.submitButton2} primary-hover`}
                onClick={() => setAddingNewPatient(true)}
                // htmlType="submit"
                // block
              >
                New Patient
              </button>
            </div>
          </>
        )}
        {!patientDataLoading && addingNewPatient && (
          <>
            <Form
              layout="vertical"
              form={form}
              //   onFinish={onFinish}
            >
              <Form.Item
                //   className={classes.inputHalf}
                label="Name"
                name="patient_name "
                rules={[
                  {
                    required: true,
                    message: "Please enter your name!",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <div className={classes.formInputContainer}>
                <Form.Item
                  className={classes.inputHalf}
                  label="OP Number"
                  name="op_number"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your OP number!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="OP Number" />
                </Form.Item>

                <Form.Item
                  className={classes.inputHalf}
                  label="Email"
                  name="email"
                  rules={[
                    { required: false, message: "Please enter your Email!" },
                  ]}
                >
                  <Input placeholder="Enter your Email" />
                </Form.Item>
              </div>
              <div className={classes.formInputContainer}>
                <Form.Item
                  className={classes.inputHalf}
                  label="Age"
                  name="age"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Age!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Age" />
                </Form.Item>

                <Form.Item
                  className={classes.inputHalf}
                  label="Gender"
                  name="gender"
                  rules={[{ required: true, message: "Select Gender!" }]}
                >
                  <Select
                    placeholder="Select Gender"
                    showSearch
                    allowClear
                    // onChange={(value) => setSelectedBranch(value)}
                    className="form-select"
                    optionFilterProp="children" // Ensure filtering works on option labels
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    // omChange={(value)=> setSelectedToken(value)}
                  >
                    {genderList?.map((item) => (
                      <Option key={item?.value} value={item?.value}>
                        {item?.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                className={`${classes.inputFull} text-area`}
                label="Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Address!",
                  },
                ]}
              >
                <TextArea
                  placeholder="Address"
                  rows={5}
                  className="text-area"
                />
              </Form.Item>
              <Form.Item>
                <div className={classes.buttonDiv}>
                  <button
                    className={`${classes.submitButton2} primary-hover`}
                    onClick={() => setAddingNewPatient(false)}
                    disabled={creatingNewPatient}
                    // htmlType="submit"
                    // block
                  >
                    Go Back
                  </button>
                  <button
                    className={`${classes.submitButton} primary-hover`}
                    onClick={() => addPatientAndBook()}
                    // htmlType="submit"
                    // block
                  >
                    {creatingNewPatient && <LoadingOutlined />} Book Now
                  </button>
                </div>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default PatientSelection;
