import React, { useEffect, useState } from "react";
import classes from "./LabReport.module.css";
import { Form, Input, message, notification } from "antd";
import labReportService from "../../../services/labReport";
import { LoadingOutlined } from "@ant-design/icons";

const LabReport = ({ setShowLabReportModal }) => {
  const [form] = Form.useForm();
  const [bill, setBill] = useState();
  const [labLoading, setLabLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onFinish = () => {
    setLabLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        console.log("values", values);

        try {
          const data = await labReportService.getLabReport(
            values.username,
            values.password
          );
          setLabLoading(false);
          console.log("response data", data);
          if (!data.status == "error") {
            downloadPdf(data.data);
            setBill(data.billNo);
            setLabLoading(false);
          }
          if (data.status === "error") {
            setLabLoading(false);
            notification.error({
              message: data.msg || data.message,
            });
          }
        } catch (error) {
          setLabLoading(false);
          console.error("Error fetching lab report:", error);
          const message = error?.response?.data?.message;
          notification.error({
            message: message || "Failed to fetch lab report.",
          });
        }
      })
      .catch((err) => {
        setLabLoading(false);
        console.log("error", err);
      });
  };

  const downloadPdf = (image) => {
    // window.open(`data:application/pdf;base64,${image}`,'_blank');
    // const linkSource = `data:application/pdf;base64,${image}`;
    // const downloadLink = document.createElement("a");
    // const fileName = "Lab_report.pdf";
    // downloadLink.href = linkSource;
    // downloadLink.download = fileName;
    // downloadLink.click();

    // Convert base64 PDF data to binary data
    const binaryData = atob(image);

    // Create an array buffer to store the binary data
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    // Convert binary data to array buffer
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the array buffer
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });

    // Create a data URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a download link
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = "Lab_report.pdf";

    // Trigger a click on the link to start the download
    a.click();

    // Release the object URL to free up resources
    URL.revokeObjectURL(blobUrl);
    setSuccess(true);
  };

  useEffect(() => {
    if (success) {
      const timeoutId = setTimeout(() => {
        // Code to be executed after the timeout
        setShowLabReportModal(false);
        setSuccess(false);
      }, 3000); // 1000 milliseconds (1 second) in this example

      // Clean up the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [success]);

  return (
    <div className={classes.reportContainer}>
      <h4 className={classes.heading}>
        Download <span>Lab Report</span>
      </h4>
      <p className={classes.description}>
        Get your username and password from the bill for your lab test.
      </p>
      <Form
        layout="vertical"
        form={form}
        //   onFinish={onFinish}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please enter your Username!" }]}
        >
          <Input placeholder="Enter your Username" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="Password"
          rules={[{ required: true, message: "Please enter your Password!" }]}
        >
          <Input placeholder="Enter your Password" />
        </Form.Item>

        <Form.Item>
          <button
            className={`${classes.submitButton} primary-hover`}
            onClick={() => onFinish()}
            // htmlType="submit"
            // block
          >
            {labLoading && <LoadingOutlined />} Get Lab Report
          </button>
          {/* <Button type="primary" htmlType="submit" block>
            Submit
          </Button> */}
        </Form.Item>
      </Form>
    </div>
  );
};

export default LabReport;
