import React, { useState } from "react";
import classes from "./VerifyOtp.module.css";
import { LoadingOutlined } from "@ant-design/icons";
import OTPInput, { ResendOTP } from "otp-input-react";
import BookingService from "../../../services/booking";
import { notification } from "antd";

const VerifyOtp = ({
  bookingStep,
  doctorDetails,
  dateParam,
  registerFormData,
  setRegisterFormData,
  nextStepHandler,
}) => {
  const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
  const [OTP, setOTP] = useState();

  const otpInputStyles = {
    inputStyles: {
      //   width: "500px", // Set the width to your desired value
      //   "@media (max-width: 600px)": {
      //     marginRight: "0 !important", // Set a different width for screens with a max width of 600px
      //   },
      //   marginRight: "0px !important",
      //   border: "1px solid #007991",
      //   fontSize: "18px",
      //   height: "79px",
      //   // Add any other styles you need for the input boxes
    },
    containerStyles: {
      display: "flex",
      //   border: "1px solid green",
      gap: "40px", // Set the custom flex gap to your desired value
      // Add any other styles you need for the OTP input container
    },
  };

  const generateOtpAgain = async () => {
    const otpgenerated = await BookingService.generateOtp(
      registerFormData.encrypted_mobile,
      registerFormData.visit_id
    );

    if (otpgenerated.status == "success") {
      notification.success({
        message: "An OTP has been sent to your mobile number.",
      });
    }
  };

  const verifyOtp = async () => {
    if (!OTP || OTP?.length !== 4) {
      notification.error({
        message: "Enter a valid OTP.",
      });
      return;
    }
    setVerifyOtpLoading(true);
    const newRegistrationFormData = { ...registerFormData, otp: OTP };
    setRegisterFormData({ ...registerFormData, otp: OTP });
    const response = await BookingService.verifyOtp(
      OTP,
      newRegistrationFormData
    );

    if (response.status == "success") {
      const finalkey = await BookingService.contactEncryption(OTP);

      setRegisterFormData({
        ...registerFormData,
        otp: OTP,
        encrypted_otp: finalkey,
      });
      notification.success({
        message: "OTP verification successful.",
      });
      setVerifyOtpLoading(false);
      nextStepHandler();
    } else {
      notification.error({
        message: "Invalid OTP",
      });
      setVerifyOtpLoading(false);
    }

    setOTP("");
  };

  return (
    <div className={classes.sectionContianer}>
      <h4 className={classes.sectionHeading}>
        Verify Your <span>Contact Information</span>
      </h4>
      <h5 className={classes.textLine}>
        Enter the OTP sent to your mobile number to confirm your appointment.
      </h5>
      <div className={classes.otpInputSection}>
        <OTPInput
          autoFocus
          OTPLength={4}
          otpType="number"
          value={OTP}
          onChange={setOTP}
          inputClassName="otp-input"
          containerClassName
          {...otpInputStyles}
        />
      </div>
      <button
        className={`${classes.submitButton} primary-hover`}
        onClick={() => verifyOtp()}
        // htmlType="submit"
        // block
      >
        {verifyOtpLoading && <LoadingOutlined />} Verify OTP
      </button>
      {/* <h4 className={classes.resendOtp}>
        Haven't received the code? <span>Resend OTP</span>{" "}
      </h4> */}
      <ResendOTP
        renderButton={({ onClick, remainingTime }) => {
          const canSendOTP = remainingTime === 0;
          return (
            <h4 className={classes.resendOtp}>
              Haven't received the code?{" "}
              {!canSendOTP ? (
                <span
                  className={classes.resendTime}
                >{`Resend in ${remainingTime}`}</span>
              ) : (
                <span onClick={() => onClick()}>Resend OTP</span>
              )}{" "}
            </h4>
          );
          //   return (
          //     <p className="resendText">
          //       Didn't receive code? <span>{!canSendOTP ? "Resend in" : ""}</span>
          //       <span
          //         className={!canSendOTP ? "disabled" : undefined}
          //         onClick={canSendOTP ? onClick : () => {}}
          //       >
          //         {canSendOTP ? ` Resend OTP` : ` ${remainingTime}`}
          //       </span>
          //     </p>
          //   );
        }}
        onResendClick={generateOtpAgain}
        renderTime={() => false}
        className="resendOtp"
      />
    </div>
  );
};

export default VerifyOtp;
