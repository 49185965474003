import fetch from "../components/Fetchinterceptor/FetchInterceptor";


const servicesService = {}
const apiRoute = '/services';

export const hellohidata = 'hi'

servicesService.getAll = async function (query = '') {
    try {
        const res = await fetch({
            url: `${apiRoute}/getAll/public${query}`,
            method: 'get'
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
        return err
    }
}

servicesService.getById = async function (id) {
    try {
        const res = await fetch({
            url: `${apiRoute}/get/public/${id}`,
            method: 'get',
        })
        return res
    } catch (err) {
        console.log(err, 'show-err')
    }
}

servicesService.getBySlug = async function (slug) {
    try {
        const res = await fetch({
            url: `${apiRoute}/get/slug/${slug}`,
            method: 'get',
        })
        return res
    } catch (err) {
        return err
        console.log(err, 'show-err')
    }
}

export default servicesService;