import _ from "lodash";
import fetch from "../components/Fetchinterceptor/FetchInterceptor";
import forge from 'node-forge';

let cache = null;

//const API_URL = process.env.REACT_APP_API_URL;
const APP_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//const APP_BASE_URL = 'http://202.88.240.171:3415/hmis_testing/public/index.php/';

const BookingService = {}

BookingService.getBranchDetails = async () => {
    try {
        let url = `${APP_BASE_URL}/booking/branch-list`;

        return await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
            },
        })
            .then((response) => response)
    } catch (err) {
        console.error(err)
    }
};

// BookingService.getAllDoctorsList = async (selectedBranch = "5d0b81695aa247314f66de30", selectedDepartment = "") => {
//     try {
//         const accessToken = window.localStorage.getItem("accesstoken");
//         console.log("access Token from local storage", accessToken);
//         let url = `${APP_BASE_URL}/api/v1/doctor-in-department`;

//         //  const dateToUse = date?date:"24/06/2023"

//         return await fetch(url, {
//             method: "POST",
//             headers: {
//                 // "Content-Type": "application/json",
//                 // Accept: "application/json",
//                 Authorization: `Bearer ${accessToken}`,
//             },
//             data: JSON.stringify({ branch_id: selectedBranch, doctor_list: { department: selectedDepartment } }),
//             // data: JSON.stringify({ branch_id: selectedBranch }),
//         })
//             .then((response) => response)
//         //   .then((response) => response.json())
//         //   .then((data) => data.data);
//     } catch (err) {
//         console.error(err)
//     }
// };

//old
BookingService.getAllDoctorsList = async (selectedBranch = "5d0b81695aa247314f66de30", selectedDepartment = "") => {
    try {
        const accessToken = window.localStorage.getItem("accesstoken");
        console.log("access Token from local storage", accessToken);
        let url = `${APP_BASE_URL}/booking/doctor-list`;

        //  const dateToUse = date?date:"24/06/2023"

        return await fetch(url, {
            method: "POST",
            headers: {
                // "Content-Type": "application/json",
                // Accept: "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify({ branch_id: selectedBranch, department_id: selectedDepartment }),
            // data: JSON.stringify({ branch_id: selectedBranch }),
        })
            .then((response) => response)
        //   .then((response) => response.json())
        //   .then((data) => data.data);
    } catch (err) {
        console.error(err)
    }
};

// BookingService.getAllDepartmentsBooking = async (branch_id = "5d0b81695aa247314f66de30") => {
//     try {
//         const accessToken = window.localStorage.getItem('accesstoken')
//         console.log('access Token from local storage', accessToken);

//         let url = `${APP_BASE_URL}/api/v1/list-departments-by-branch`;

//         return await fetch(url, {
//             method: "POST",
//             headers: {
//                 // "Content-Type": "application/json",
//                 // Accept: "application/json",
//                 "Authorization": `Bearer ${accessToken}`,
//             },
//             data: JSON.stringify({ branch_id: branch_id ? branch_id : "5d0b81695aa247314f66de30" }),

//         })
//             .then((response) => response)
//     } catch (err) {
//         console.error(err)
//     }
// };

//old
BookingService.getAllDepartmentsBooking = async (branch_id = "5d0b81695aa247314f66de30") => {
    try {
        const accessToken = window.localStorage.getItem('accesstoken')
        console.log('access Token from local storage', accessToken);

        let url = `${APP_BASE_URL}/booking/list-departments`;

        return await fetch(url, {
            method: "POST",
            headers: {
                // "Content-Type": "application/json",
                // Accept: "application/json",
                "Authorization": `Bearer ${accessToken}`,
            },
            data: JSON.stringify({ branch_id: branch_id ? branch_id : "5d0b81695aa247314f66de30" }),

        })
            .then((response) => response)
    } catch (err) {
        console.error(err)
    }
};

BookingService.getDoctorsBooking = async (date, selectedDocParam = "", selectedDeptParam = "", selectedBranchParam = "", page = 1, limit = 50) => {
    try {
        const values = {
            date: date,
            branch_id: selectedBranchParam,
            department_id: selectedDeptParam,
            doctor_id: selectedDocParam,
        };
        const sendingValues = _.pickBy(values, _.identity);
        const filterParams = new URLSearchParams(sendingValues)?.toString();

        console.log("date to be used", date);
        const accessToken = window.localStorage.getItem("accesstoken");
        console.log("access Token from local storage", accessToken);
        const endPoint = `doctor-consultation-available-in-date?${filterParams}`;
        // const endPoint = `doctor-consultation-available-in-date?department_id=${selectedDeptParam}&doctor_id=${selectedDocParam}`;
        const mutatedendPoint = endPoint.replaceAll("/", "%2F").replaceAll("?", "%3F").replaceAll("=", "%3D").replaceAll("&", "%26");
        let url = `${APP_BASE_URL}/booking/${mutatedendPoint}`;
        // let url = `${APP_BASE_URL}/api/v1/booking/doctor-consultation-available-in-date?department_id=${deptFilter}&doctor_id=${doctorFilter}`;

        //  const dateToUse = date?date:"24/06/2023"

        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // Accept: "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify({
                branch_id: selectedBranchParam,
                data: { date: date, page: page, page_count: limit },
                // data: { date: date, page: 1, page_count: 50 },
            }),
        })
            .then((response) => response)
    } catch (err) {
        console.error(err)
    }
};

BookingService.getToken = async (visit_id) => {
    try {
        let url = `${APP_BASE_URL}/booking/get-tokens-against-visit-detail`;

        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // Accept: "application/json",
                "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
            },
            data: JSON.stringify({
                data: {
                    visit_id: visit_id,
                },
            }),
        })
            .then((response) => response)
    } catch (err) {
        console.error(err)
    }
};

BookingService.contactEncryption = async (data) => {

    // const publicKey = `MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqll3mHoicnyleJyssrw9it3efjTQuYrzIyxN2uHegbZcFCyElNWGHPh7zOxrESLdnRlI8ehvuG1BHqwj4MadSGnuRXk7uL7aOlsajT4tKCh/Dwb2IozTPVjpAXA2ncOue/Nsh5T0jjBTaRWYa1CTTBBi/m85W6QPINYP/gtlnPpYoeLQUN78U6fMw4rcEEyzJ4AKCh8v5WdVloCMWQbpd94f3/uWAP0ES3JBDEmQbzU64a4kit/TCdXnB32yzynzypB+HpCwDCSw+DnmWFa/nbrvJZr4BByzpS/uyoLVPF6w2wi+3DkxcpdWoo1g1aWpj3z3gRt+F24LovI60+YvL1g6DHGGoXgn3pUeGckq7U5ihLBR3aqt/JbTZpb2IT9Q3D2eWbyglReCemvvcEoT+JUvK1g1DzTYT3iMiNMeOiJkYuY6zckFBZTT9jTdw0BH9mDPqWXzoZ8dZenCH4l4kilkw//Hwj56r0N16XEyoSnpgtzWl1YthlVcy4um+Ujc/bWqWaSL1+sTneMftuR46lbyIHaGDwush5cJs67nGXsuL4w1MJE1IiSZhcdja6+TuMrTgCKg1Wjbs9eF//einKCUW8Y1wkrDoBOSYRkbNmfX9rNzCUjXPay8crZK8sg48eNHnriVFFPNjcmHWVZ9oDVEQnOT2TpPPFnEuK74/9sCAwEAAQ==`

    const publicKey = `-----BEGIN PUBLIC KEY-----
    MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqll3mHoicnyleJyssrw9
    it3efjTQuYrzIyxN2uHegbZcFCyElNWGHPh7zOxrESLdnRlI8ehvuG1BHqwj4Mad
    SGnuRXk7uL7aOlsajT4tKCh/Dwb2IozTPVjpAXA2ncOue/Nsh5T0jjBTaRWYa1CT
    TBBi/m85W6QPINYP/gtlnPpYoeLQUN78U6fMw4rcEEyzJ4AKCh8v5WdVloCMWQbp
    d94f3/uWAP0ES3JBDEmQbzU64a4kit/TCdXnB32yzynzypB+HpCwDCSw+DnmWFa/
    nbrvJZr4BByzpS/uyoLVPF6w2wi+3DkxcpdWoo1g1aWpj3z3gRt+F24LovI60+Yv
    L1g6DHGGoXgn3pUeGckq7U5ihLBR3aqt/JbTZpb2IT9Q3D2eWbyglReCemvvcEoT
    +JUvK1g1DzTYT3iMiNMeOiJkYuY6zckFBZTT9jTdw0BH9mDPqWXzoZ8dZenCH4l4
    kilkw//Hwj56r0N16XEyoSnpgtzWl1YthlVcy4um+Ujc/bWqWaSL1+sTneMftuR4
    6lbyIHaGDwush5cJs67nGXsuL4w1MJE1IiSZhcdja6+TuMrTgCKg1Wjbs9eF//ei
    nKCUW8Y1wkrDoBOSYRkbNmfX9rNzCUjXPay8crZK8sg48eNHnriVFFPNjcmHWVZ9
    oDVEQnOT2TpPPFnEuK74/9sCAwEAAQ==
    -----END PUBLIC KEY-----`

    const rsaPublicKey = forge.pki.publicKeyFromPem(publicKey)
    const plainText = '7356386380'
    const encrypted = rsaPublicKey.encrypt(data, 'RSA-OAEP')
    console.log('encripty', forge.util.encode64(encrypted))
    const base64encoded = forge.util.encode64(encrypted);
    return base64encoded;

}

BookingService.generateOtp = async (encodeValue, visit_id) => {
    try {
        const accessToken = window.localStorage.getItem("accesstoken");
        let url = `${APP_BASE_URL}/booking/booking-otp`;

        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify({
                data: {
                    mobile_number: encodeValue,
                    visit_id: visit_id,
                },
            }),
        })
            .then((response) => response)
        //   .then((data) => {
        //     console.log("encrypted otp", data);
        //     setRegisterFormData({
        //       ...registerFormData,
        //       // encrypted_otp: data.encryptedOtp,
        //       encrypted_mobile: encodeValue,
        //       // otp: data.otp,
        //     });
        //   });
    } catch (err) {
        console.error(err)
    }
};

BookingService.verifyOtp = async (otp, registerFormData) => {
    try {
        const accessToken = window.localStorage.getItem("accesstoken");
        const mobileKey =
            "LkWwSGbP9LOC0GRlxwCZTi3sGpSwXTM+fXN/QuEMGfERpxocwbg0b45a2ZzkcoEgagqz8CUA4MD2pFiwA5fksGNpwNfjz4AypxKvkV3PL1XKQWV8XXarCewvpeBk5TDVsmuYfMtP4QFnLQ4SGiUWUV2sNo6OTbUIUi04Fp/RYPhavwgFoR4AmWcmrGoM9A4KRSF7xONHEpuhoGlq7He2eg+tk9Q16vUXRvlk+nHoPtsH4oY2GBW5KTJQYei/KKU10XFD1viny4ZK6PUmSn3c7mD73kK1i+hzvJYnQSF4y/ukvwXkEcqfkEOiy5aGZdug7pR5214LJS0shnaCAOIMh4WsAJkUnmby8QxuhrsoxbdnQHG94PSdPaxvjFVNGxNXMR/akFI3APMANl1SDjyuDSheWDXxyLgFCj9cnZ3NNhfKfBYj16MeJILEKSSImxvaoEroxnfFn3jssG6rLmo8SX5NR6RdmsDEGOYoSIARWH3D/NJlo/EBCHvGZ8PE1kNk8KqgKfdFy2zxOIOmvLevwQ95dAztkQCuDpg3qMMMF9Qe6N5nfsKc4BXV5+YC7jOQPzZ5+r3QONlRaQqf4WyL7aJYcIq97D01jfYKrY7s4TZ7qIBouQjQGtArcyfuCEV2D/hMyrPGGcAXnw2oVrOhzegnEALadbJSXND3vWfS4pE=";
        let url = `${APP_BASE_URL}/booking/verify-otp`;

        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify({
                data: {
                    visit_id: registerFormData.visit_id,
                    mobile_number: registerFormData.mobileNumber,
                    // otp: registerFormData.encrypted_otp,
                    otp: otp,
                },
            }),
        })
            .then((response) => response)
    } catch (err) {
        console.error(err)
    }
};

BookingService.getGenderList = async () => {
    try {
        // const accessToken = window.localStorage.getItem('accesstoken')
        let url = `${APP_BASE_URL}/booking/load-gender-master`;

        return fetch(url, {
            method: "POST",
            headers: {
                // "Content-Type": "application/json",
                // Accept: "application/json",
                "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
            },
        })
            .then((response) => response)
            .then((data) => data.data)
    } catch (err) {
        console.error(err)
    }
};

BookingService.getPatientData = async (registerFormdata) => {
    try {
        let url = `${APP_BASE_URL}/booking/patient-basic-details`;

        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
            },
            data: JSON.stringify({
                data: {
                    "visit_id": registerFormdata.visit_id,
                    "otp": registerFormdata.encrypted_otp,
                    "mobile_number": registerFormdata.encrypted_mobile,
                },
            }),
        })
            .then((response) => response)
    } catch (err) {
        console.error(err)
    }
};

BookingService.confirmBooking = async (data) => {
    try {
        // const accessToken = window.localStorage.getItem('accesstoken')
        let url = `${APP_BASE_URL}/booking/confirm-booking`;

        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // Accept: "application/json",
                "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
            },
            data: JSON.stringify({
                data: data
            }),
        })
            .then((response) => response)
    } catch (err) {
        console.error(err)
    }
};

BookingService.payLater = async (data) => {
    try {
        // const accessToken = window.localStorage.getItem('accesstoken')
        let url = `${APP_BASE_URL}/booking/booking-pay-later`;

        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
            },
            data: JSON.stringify({
                data
            }),
        })
            .then((response) => response)
    } catch (err) {
        console.error(err)
    }
};

BookingService.payNowConfirm = async (data) => {
    // const accessToken = window.localStorage.getItem('accesstoken')
    let url = `${APP_BASE_URL}/booking/pay-now-confirm`;
    console.log('cred data', data)
  
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
      },
      body: JSON.stringify({transaction_id: '15864'}),
    })
      .then((response) => response)
      .then((data) => data.data)
  };
  
  BookingService.getHashValue = async (data) => {
    // let url = `${API_URL}/api/v1/booking/payu-hashing`;
    let url = `${APP_BASE_URL}/payment/payu-hashing`;
  
  
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
      },
      body: JSON.stringify({
        txnid: data.transaction_id,
        amount: data.amount,
        productinfo: data.product_name,
        firstname: data.firstname,
        email: data.email,
        udf1: "WEB",
      }),
    })
      .then((response) => response)
      .then((data) => data.data)
  }

export default BookingService;



