import React, { useRef, useState } from "react";
import classes from "./Hospitals.module.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import NavbarSliderLeftIcon from "../../../UI/NavbarSliderLeftIcon";
import NavbarSliderRightIcon from "../../../UI/NavbarSliderRightIcon";
import { Link } from "react-router-dom";

const Hospitals = ({ branches, branchesLoading, setShowHospitalsDropdown }) => {
  const [branchImages, setBranchImages] = useState(
    branches?.length > 0 ? branches[0].images : []
  );
  const [branchDetails, setBranchDetails] = useState(
    branches?.length > 0 ? branches[0].name : undefined
  );
  console.log("branches 0000", branches);
  const swiperRef = useRef(null);
  return (
    <div className={classes.outerContainer}>
      {branchesLoading && (
        <div className={classes.loadingDiv}>
          <LoadingOutlined className={classes.loadingIcon} />
        </div>
      )}
      {!branchesLoading && (
        <div className={classes.menuContainer}>
          <div className={classes.leftSection}>
            <h5 className={classes.leftTitle}>
              Accessible Care Across Locations
            </h5>
            <div className={classes.divideLine}></div>
            <div className={classes.leftContainer}>
              {branches?.map((branch) => (
                <Link
                  to={`/branch/${branch.slug}`}
                  onClick={() => setShowHospitalsDropdown(false)}
                >
                  <div
                    className={classes.card}
                    key={branch._id}
                    onMouseEnter={() => {
                      setBranchImages(
                        branch.images?.filter(
                          (image) => image.mainImage == "yes"
                        )
                      );
                      setBranchDetails(branch);
                    }}
                  >
                    <div className={classes.locationDiv}>
                      <img src="/img/icons/nav-location.svg" alt="location" />
                      <h4 className={classes.text}>{branch.location}</h4>
                    </div>
                    <h4 className={classes.branchName}>{branch.name}</h4>
                    <div className={classes.contactDiv}>
                      <img src="/img/icons/navPhone.svg" alt="contact" />
                      <h4 className={classes.text}>{branch.phoneNumber}</h4>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className={classes.rightSection}>
            <div
              className={classes.sliderContainer}
              // onMouseEnter={() => swiperRef.current?.autoplay.stop()} // Stop autoplay on hover
              // onMouseLeave={() => swiperRef.current?.autoplay.start()}
            >
              <Swiper
                className="dropdown-swiper"
                modules={[Navigation, Pagination, Autoplay]} // Enable navigation and pagination
                slidesPerView={1} // Show 3 images at a time
                spaceBetween={0} // Add space between slides
                onSwiper={(swiper) => (swiperRef.current = swiper)} // Attach the Swiper instance to the ref
                pagination={{
                  clickable: true,
                }} // Enable clickable dots
                loop={true} // Enable infinite looping
                style={{ marginBottom: "-4px" }} // Add space for pagination dots
                draggable={true} // Enable drag/swipe functionality
                autoplay={{
                  delay: 2000, // Delay between transitions (in milliseconds)
                  disableOnInteraction: false, // Keep autoplay running even after user interaction
                }}
              >
                {branchImages?.map((image, index) => (
                  <SwiperSlide key={index}>
                    {/* {infoDiv()} */}
                    <img
                      src={image.url}
                      alt={image?.imageTag}
                      className={classes.sliderImage}
                    />
                    <h4 className={classes.swiperImageText}>
                      {branchDetails.name}
                    </h4>
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* <div className={classes.buttonsDiv}>
                <NavbarSliderLeftIcon
                  className={classes.leftIcon}
                  onClick={() => swiperRef.current?.slidePrev()}
                />
                <NavbarSliderRightIcon
                  className={classes.rightIcon}
                  onClick={() => swiperRef.current?.slideNext()}
                />
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hospitals;
