import axios from "axios";
import fetch from "../components/Fetchinterceptor/FetchInterceptor";


const labReportService = {}
const API_URL = process.env.REACT_APP_API_URL;
const APP_BASE_URL = process.env.REACT_APP_API_BASE_URL;

labReportService.getLabReport = async function (username, password) {
    let url = `${APP_BASE_URL}/booking/get-lab-report-against-bill-credentials`;
    try {
        const res = fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
            },
            body: JSON.stringify({
                data: {
                    username: username,
                    password: password,
                    // otpTxtN: otpTxtN,
                    // otpCheck: otpCheck,
                },
            }),
        })
            .then((response) => response)
            .then((data) => data);

        // if (!res.ok) {
        //     // You can handle specific status codes here
        //     throw new Error(`HTTP error! status: ${res.status}`);
        // }
        // const data = await res.json();
        return res;
    } catch (err) {
        console.log(err, 'show-err');
        return err
    }
}

export default labReportService;