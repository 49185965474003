import fetch from "../components/Fetchinterceptor/FetchInterceptor";

let cache = null;

const API_URL = process.env.REACT_APP_API_URL;
const APP_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const tokenService = {}

tokenService.getAPIAccessToken = async () => {
    let url = `${APP_BASE_URL}/booking/web-site-login`;
    // let url = "api/v1/web-site-login";

    return await fetch(url, {
        method: "POST",
        headers: {
            // "Content-Type": "application/json",
            Accept: "application/json",
        },
        // body: JSON.stringify({
        //   data: {
        //     grant_type: "client_credentials",
        //     client_id:
        //       "649c58d4e184ea0b924f9b41_nfs6ei72mqogw0g0ok404444wck8ckcc8oc8o440g0k0ws0cw",
        //     client_secret: "3hagfs3mhd0k0k0cwgkccssscgcw8800ggggw8kowwck00s440",
        //   },
        // }),
    //     body: JSON.stringify({
    //       data: {
    //         grant_type: "client_credentials",
    //         client_id:
    //           "67a1b6ddf52cc2ec1a02bf61_1e83mnm0cstckwoso8g44sog0wg4kowwkk84cw04w0wocckwsk",
    //         client_secret: "52gwj22hcfgo40w0w800cgs80sookk08ks04804k0wg0wkowco",
    //       },
    //     }),
     })
        .then((response) => response)
    // .then((data) => data.data);
    // .then((json) => {
    //   cache = json;
    // });
};

export default tokenService;



