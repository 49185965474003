import React, { useEffect } from "react";
import classes from "./PaymentSuccess.module.css";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {

  const navigate = useNavigate()

  useEffect(()=>{
    const timeoutId = setTimeout(() => {
      navigate("/");
    }, 3000);

    // Cleanup function to clear the timeout when component unmounts
    return () => clearTimeout(timeoutId);
  },[])

  return (
    <div className={classes.container2}>
      <div className={`${classes.banner} bg-primary-100`}>
        <img className={classes.icon} src="/img/icons/pay-success.svg" alt="payment-success" />

        <h2 className="primary-700">Payment Successful!</h2>
      </div>
    </div>
  );
};

export default PaymentSuccess;
