import React, { useRef } from "react";
import classes from "./Services.module.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import NavbarSliderLeftIcon from "../../../UI/NavbarSliderLeftIcon";
import NavbarSliderRightIcon from "../../../UI/NavbarSliderRightIcon";
import { Link } from "react-router-dom";

const Services = ({ services, servicesLoading, setShowServicesDropdown }) => {
  console.log("services 0000", services);
  const swiperRef = useRef(null);
  return (
    <div className={classes.outerContainer}>
      {servicesLoading && (
        <div className={classes.loadingDiv}>
          <LoadingOutlined className={classes.loadingIcon} />
        </div>
      )}
      {!servicesLoading && (
        <div className={classes.menuContainer}>
          <div className={classes.leftSection}>
            {services?.map((service) => (
              <Link
                to={`/services/${service.slug}`}
                onClick={() => setShowServicesDropdown(false)}
              >
                <div className={classes.card} key={service._id}>
                  <div className={classes.logoDiv}>
                    <img src={service.icon} alt="icon" className={classes.logo} />
                  </div>
                  <h4 className={classes.text}>{service.title}</h4>
                </div>
              </Link>
            ))}
          </div>
          <div className={classes.rightSection}>
            <div
              className={classes.sliderContainer}
              // onMouseEnter={() => swiperRef.current?.autoplay.stop()} // Stop autoplay on hover
              // onMouseLeave={() => swiperRef.current?.autoplay.start()}
            >
              {/* <Swiper
                modules={[Autoplay]}
                spaceBetween={10}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                slidesPerView={1}
                loop={true}
                // draggable={true} // Enable drag/swipe functionality
                // autoplay={{
                //   delay: 3000, // 3 seconds
                //   disableOnInteraction: false, // Autoplay continues even after interaction
                // }}
              > */}
              <Swiper
                className="dropdown-swiper"
                modules={[Navigation, Pagination, Autoplay]} // Enable navigation and pagination
                slidesPerView={1} // Show 3 images at a time
                spaceBetween={0} // Add space between slides
                onSwiper={(swiper) => (swiperRef.current = swiper)} // Attach the Swiper instance to the ref
                pagination={{
                  clickable: true,
                  // renderBullet: (index, className) => {
                  //   const maxDots = 5; // Set the maximum number of dots
                  //   const totalSlides = servicesImages?.length || 0;
                  //   const dotsToShow = Math.min(totalSlides, maxDots); // Show up to maxDots or totalSlides

                  //   // Only render dots for the first `maxDots` slides
                  //   if (index < dotsToShow) {
                  //     return `<span class="${className}"></span>`;
                  //   }
                  //   return "";
                  // },
                }} // Enable clickable dots
                loop={true} // Enable infinite looping
                style={{ marginBottom: "-4px" }} // Add space for pagination dots
                draggable={true} // Enable drag/swipe functionality
                autoplay={{
                  delay: 2000, // Delay between transitions (in milliseconds)
                  disableOnInteraction: false, // Keep autoplay running even after user interaction
                }}
              >
                {services?.map((service, index) => (
                  <SwiperSlide key={index}>
                    {/* {infoDiv()} */}
                    <img
                      src={service.image.url}
                      alt={service?.image?.imageTag}
                      className={classes.sliderImage}
                    />
                    <h4 className={classes.swiperImageText}>{service.title}</h4>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className={classes.buttonsDiv}>
                <NavbarSliderLeftIcon
                  className={classes.leftIcon}
                  onClick={() => swiperRef.current?.slidePrev()}
                />
                <NavbarSliderRightIcon
                  className={classes.rightIcon}
                  onClick={() => swiperRef.current?.slideNext()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
