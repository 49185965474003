import fetch from "../components/Fetchinterceptor/FetchInterceptor";


const informationPolicyService = {}
const apiRoute = '/information-policy';

informationPolicyService.getAll = async function (query = '') {
    try {
        const res = await fetch({
            url: `${apiRoute}/getAll/public${query}`,
            method: 'get'
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
        return err
    }
}

informationPolicyService.getById = async function (id) {
    try {
        const res = await fetch({
            url: `${apiRoute}/get/${id}/public`,
            method: 'get',
        })
        return res
    } catch (err) {
        console.log(err, 'show-err')
    }
}

informationPolicyService.getBySlug = async function (slug) {
    try {
        const res = await fetch({
            url: `${apiRoute}/getBySlug/${slug}`,
            method: 'get',
        })
        return res
    } catch (err) {
        console.log(err, 'show-err')
        return err
    }
}

export default informationPolicyService;