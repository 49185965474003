import React from "react";
import classes from "./NotFound.module.css";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.outer}>
      <img src="/img/404.png" alt="not-found" />
      <h3 className="error-head">Error 404</h3>
      <p className="error-para">
        {`Unfortunately, the page you're looking for does not exist!`}
      </p>
      <button
        onClick={() => navigate("/")}
        className="border-primary-500 primary-hover"
      >
        Back To Home
      </button>
    </div>
  );
};

export default NotFoundPage;
